import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import SimpleMap from "../components/map";
import ContactListing from "../components/contact-listing";
import Details from "../components/details";
import NoExclusiveSlider from "../components/no-exclusive-slider"
import VirtualTour from "../components/virtual-tour";


const NonExclusive = ({ data, location }) => {
  const property = data.listingResidential;
  const center = {
    lat: property ? property.Latitude : null,
    lng: property ? property.Longitude : null
  }

  const galleryImages = property.Media.map(m => ({
    original: m.MediaURL.replace('WIDTH', 1200).replace('HEIGHT', 1200 / 1.5),
    thumbnail: m.MediaURL.replace('WIDTH', 150).replace('HEIGHT', 150 / 1.5)
  }));

  return (
    <Layout>
      <Seo title={property.UnparsedAddress} location={location} />
      <div className="cha-nel-container">
        <div className="cha-nel-container__image_gallery">
          {property.Media.length > 0 ? (
            <NoExclusiveSlider items={galleryImages} />
          ) : null}

          <div></div>
          <div className="cha-nel-container__about">
            <div className="cha-nel-container__about__inner">
              <div className="cha-nel-container__about__inner">
                <h1 className="h1-header">
                  About this property
                </h1>
                <p className="subhead">{`$${new Intl.NumberFormat().format(property.ListPrice)} | ${property.UnparsedAddress}`}</p>
                {property.StandardStatus === 'Closed' && (
                  <p className="subhead">This Property Is No Longer On The Market</p>
                )}
                <p className="body1">
                  {property.PublicRemarks}
                </p>

                <Link
                  className="cha-nel-container__about__button"
                  to="#contact-listing"
                >
                  Inquire about this property
                </Link>
              </div>
            </div>
          </div>
          <VirtualTour videoUrl={property.VideoUrl} exclusive={false} tourUrl={property.VirtualTourURLUnbranded} unparsedAddress={property.UnparsedAddress} />
          <Details property={property} />

          <div className="cha-nel-container__location__container">
            <h1 className="h1-header">Location</h1>
            <div className="cha-nel-container__location__map">
              <SimpleMap
                zoom={14}
                center={center}
                address={property.StreetNumber + " " + property.StreetName}
              ></SimpleMap>
            </div>
          </div>

          <ContactListing listingAddress={property.UnparsedAddress + ", " + property.City} listingPrice={property.ListPrice} officeName={property.ListOfficeName} listingId={property.ListingId} logo={data.logo.logo.url} siteTitle={data.site.siteMetadata?.title || `Title`} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($mlsId: String!) {
    listingResidential(ListingId: { eq: $mlsId }) {
      ListingId
      UnparsedAddress
      BathroomsTotalInteger
      BedroomsTotal
      City
      ListOfficeMlsId
      ListOfficeName
      ListPrice
      LivingArea
      PrefferedPhoto
      PropertyType
      Furnished
      FireplaceYN
      CoolingYN
      Media {
        MediaURL
        ShortDescription
      }
      Latitude
      Longitude
      StreetNumber
      StreetName
      PublicRemarks
      ArchitecturalStyle
      AssociationAmenities
      AttachedGarageYN
      BathroomsFull
      BathroomsHalf
      BathroomsThreeQuarter
      BuildingAreaTotal
      Cooling
      CountyOrParish
      FireplaceFeatures
      FireplacesTotal
      Heating
      HeatingYN
      LaundryFeatures
      LotSizeArea
      LotSizeSquareFeet
      LotSizeUnits
      MLSAreaMajor
      MLSAreaMinor
      ParkingFeatures
      PostalCode
      PropertyCondition
      Roof
      RoomsTotal
      StateOrProvince
      YearBuilt
      CustomFields {
        rows {
          text
          value
        }
        title
      }
      VirtualTourURLUnbranded
      StandardStatus
      VideoUrl
    }

    logo {
      logo {
        url
      }
    }

    site {
      siteMetadata {
        title
      }
    }

  }
`

export default NonExclusive
