import React from "react"
import "./gallery.css"

const NoExclusiveImageSlide = props => {
  return (
    <div
      className={props.classNames + "  mx-auto slide-container"}
      index={props.index}
    >
      <img alt="" className="s-gallery-image" src={props.source} />
    </div>
  )
}

export default NoExclusiveImageSlide
