import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import NoExclusiveImageSlide from "./no-exclusive-image-slide"

const NoExclusiveSlider = ({ items }) => {
  const [state, setState] = useState({
    activeSlide: 0,
  })

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {

    setNav1(slider1);
    setNav2(slider2);

  }, [slider1, slider2]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    lazyLoad: false,
    focusOnSelect: true,
    arrows: true,
    variableWidth: true,
    className: "slider variable-width",
    beforeChange: (current, next) => setState({ activeSlide: next }),
    responsive: [
      {
        breakpoint: 799,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          variableWidth: true,
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          variableWidth: true,
          centerMode: true,
        },
      },
    ],
  }

  const settingsThumbs = {
    slidesToShow: 7,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    arrows: false,
    infinite: true,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '10px'
  };

  return (
    <div className="no-exclusive-slider-container">
      <Slider
        {...settings}
        asNavFor={nav2}
        ref={slider =>(setSlider1(slider))}
      >
        {items.map((item, index) => {
          return (
            <NoExclusiveImageSlide
              source={item.original}
              classNames={
                (index === state.activeSlide
                  ? "w-full"
                  : "w-11/12 opacity-50 custom-margin") +
                " text-center justify-items-center"
              }
              index={index}
              captionClass={
                index !== state.activeSlide ? "hidden" : "inline-block"
              }
              key={Math.random() * 100000 - 1 + 1}
              activeSlide={state.activeSlide}
            ></NoExclusiveImageSlide>
          )
        })}
      </Slider>
      <div className="thumbnail-slider-wrap">
          <Slider
            {...settingsThumbs}
            asNavFor={nav1}
            ref={slider => (setSlider2(slider))}>

            {items.map((slide) =>

              <div className="slick-slide" key={Math.random() * 100000 - 1 + 2}>
                <img className="slick-slide-image" src={slide.thumbnail} alt="" />
              </div>

            )}

          </Slider>
        </div>
    </div>
  )
}

export default NoExclusiveSlider
